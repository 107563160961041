import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Tooltip } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import getTimezoneCountryCode from '../../utils/getTimezoneCountryCode';
import UseTimeZoneCode from '../get-user-timezone';
import Iconify from '../iconify';
export default function RHFPhoneNumber({ name, helperText, label, defaultCountryTimeZone }) {
    const zone = UseTimeZoneCode();
    const { translate } = useLocales();
    const { control } = useFormContext();
    const handleWheel = (event) => {
        event.preventDefault();
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
            }, children: [_jsx(MuiPhoneNumber, { sx: { '& svg': { height: '1em' } }, variant: "outlined", name: name, label: `${translate(label)} `, onBlur: field.onBlur, InputProps: {
                        onWheel: handleWheel,
                    }, fullWidth: true, onWheel: handleWheel, value: field.value, inputProps: {
                        ref: field.ref,
                    }, countryCodeEditable: true, onChange: (e) => field.onChange(e), error: !!error, helperText: error && `${translate(error?.message)}`, defaultCountry: getTimezoneCountryCode(defaultCountryTimeZone || zone) || 'tn', excludeCountries: ['il'] }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2', marginTop: error ? '-24px' : '0px' } }) })] })) }));
}
