import { useEffect, useState } from 'react';
import { useSelector } from '../../redux/store';
export default function UseTimeZoneCode() {
    const { userTimezone } = useSelector((state) => state.agency);
    const [zone, setZone] = useState(userTimezone?.agenciesUser?.[0]?.agency?.timezone?.code);
    useEffect(() => {
        setZone(userTimezone?.agenciesUser?.[0]?.agency?.timezone?.code);
    }, [userTimezone]);
    return zone;
}
